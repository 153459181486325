<template>
    <div>
        <b-overlay
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <b-card>
                            <b-card-body class="">
                                <p>تساعدك هذه الأداة في تطبيق خصم على جميع المنتجات في قسم معين.</p>
                                <b-form @submit.prevent="update">
                                    <!-- Content Text -->
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-group
                                                :label="$t('Discount Percentage')"
                                            >
                                                <b-input-group>
                                                    <b-input-group-prepend is-text>
                                                        <feather-icon icon="PercentIcon" />
                                                    </b-input-group-prepend>
                                                    <b-form-input placeholder="20" v-model="discount_by_category.discount_percentage" />
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-group
                                                :label="$t('Category')"
                                            >
                                                <v-select
                                                v-model="discount_by_category.category"
                                                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                                label="name"
                                                :options="categories"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <!-- Is Active -->
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-group
                                            :label="$t('Remove a previous discount ?')"
                                            label-for="v-is-active"
                                            >
                                                <b-form-checkbox
                                                    id="v-is-active"
                                                    class="custom-control-primary"
                                                    v-model="discount_by_category.remove_discount"
                                                    name="remove_discount"
                                                    switch
                                                >
                                                    <span class="switch-icon-left">
                                                        <feather-icon icon="CheckIcon" />
                                                    </span>
                                                    <span class="switch-icon-right">
                                                        <feather-icon icon="XIcon" />
                                                    </span>
                                                </b-form-checkbox>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <!-- submit -->
                                    <b-row>
                                        <b-col cols="12">
                                            <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            type="submit"
                                            variant="primary"
                                            class="mr-1 mt-1"
                                            >
                                                <template v-if="!isLoadingSave">
                                                    {{$t('Save')}}
                                                </template>
                                                <template v-if="isLoadingSave">
                                                    {{$t('Saveing')}}...
                                                    <b-spinner small />
                                                </template>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        this.getCategories();
    },
    data() {
        return {
            discount_by_category: {
                remove_discount: false
            },
            isLoadingSave: false,
            categories: []
        }
    },
    methods: {
        update() {
            this.isLoadingSave = true;
            this.discount_by_category.category_id = this.discount_by_category.category.id;
            useJwt.post('/marketing-offers/discount-by-category/save',this.discount_by_category)
            .then((response) => {
                // console.log(response.data);
                this.isLoadingSave = false
                this.$root.showToast('success', 'save', '')
                // this.discount_by_category = response.data.data;
            })
            .catch(error => {
                // console.log(response);
                this.isLoadingSave = false
                this.$root.showAlert('error', error.response.data.message)
            });
        },
        getCategories() {
            useJwt.get('/categories/get-all')
            .then((response) => {
                this.categories = response.data.data.data
            })
            .catch(response => {
                console.log(response);
            });
        }
    },
}
</script>

<style lang="sass" scoped>

</style>
